import React from 'react';
import styled from 'styled-components';
import { style } from '../config';
import { Icon } from 'react-icons-kit';
import { phone, mail, linkedin } from 'react-icons-kit/feather';

const IconWrapper = styled.span`
  display: block;
  padding: 8px;
  svg {
    display: block;
    stroke: ${style.primary} !important;
    fill: white !important;
  }
`;

const CustomIcon = ({ style, size = 14, name }) => (
  <IconWrapper style={style}>
    <Icon icon={name} size={size} />
  </IconWrapper>
);

const Block = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${style.primary};
  border-radius: ${style.borderRadius}px;
  align-items: center;
`;

const Contact = (props) => {
  return (
    <>
      <Block>
        <img
          src='/icon-512x512.png'
          alt='Côme Pecorari'
          className='avatar'
          width={100}
          height={'auto'}
          style={{ margin: 16 }}
        />
        <div style={{ display: 'inline-flex', alignItems: 'center' }}>
          <CustomIcon name={phone} />
          06 87 74 04 73
        </div>
        <div style={{ display: 'inline-flex', alignItems: 'center' }}>
          <CustomIcon name={linkedin} />
          <a href='https://www.linkedin.com/in/comepecorari/'>LinkedIn</a>
        </div>
        <div style={{ display: 'inline-flex', alignItems: 'center' }}>
          <CustomIcon name={mail} />
          <a href='mailto:come@pecorari.fr'>come@pecorari.fr</a>
        </div>
      </Block>
    </>
  );
};

export { Contact };
