import React from 'react';

const Realisations = () => {
  return (
    <div>
      <h3>Copristo / Application de copropriété</h3>
      <img src='/img/copristo.png' style={{ maxWidth: '100%' }} />
      <h3>La boite à gros mots</h3>
      <p>
        Projet personnel de développement d'une application mobile déployée sur
        Android et iOS.
        <br />
        Développement en React-Native, backend en NodeJS et MongoDB.
      </p>
      <img src='/img/App-Store_Android.png' style={{ maxWidth: '100%' }} />
    </div>
  );
};

export default Realisations;
