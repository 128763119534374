import React from 'react';

const Footer = (props) => {
  return (
    <div className='footer'>
      <ul>
        <li>Côme Pecorari ©</li>
        <li>Reproduction autorisée</li>
        <li>- 2023 -</li>
      </ul>
    </div>
  );
};

export { Footer };
