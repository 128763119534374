import React from 'react';
import { style } from '../config';
import { CircleDemo } from './StarBoop';
import { Icon } from 'react-icons-kit';
import { star } from 'react-icons-kit/feather/star';
import styled from 'styled-components';

const IconWrapper = styled.span`
  display: block;
  svg {
    display: block;
    stroke: ${style.secondary} !important;
    fill: white !important;
  }
`;
const Star = ({ style, size }) => (
  <IconWrapper style={style}>
    <Icon icon={star} size={size} />
  </IconWrapper>
);

const RecoBox = ({
  avis = {
    quality: 5,
    delays: 5,
    comm: 5,
    name: 'J Jm - MARKUS',
    date: '21/12/2020',
    desc: 'Excellent relationnel. Sérieux et compétent.',
  },
}) => {
  return (
    <div
      style={{
        border: `1px solid ${style.secondary}`,
        borderRadius: style.borderRadius,
        padding: 16,
        marginTop: 16,
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <span style={{ fontWeight: 300 }}>Qualité</span>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {[...Array(avis.quality)].map((e, i) => (
              <Star
                key={`recobox_${i}_${avis.name}_quality`}
                size={12}
                style={{ marginLeft: 4 }}
              />
            ))}
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <span style={{ fontWeight: 300 }}>Respect des délais</span>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {[...Array(avis.delays)].map((e, i) => (
              <Star
                key={`recobox_${i}_${avis.name}_delays`}
                size={12}
                style={{ marginLeft: 4 }}
              />
            ))}
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <span style={{ fontWeight: 300 }}>Communication</span>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {[...Array(avis.comm)].map((e, i) => (
              <Star
                key={`recobox_${i}_${avis.name}_comm`}
                size={12}
                style={{ marginLeft: 4 }}
              />
            ))}
          </div>
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <p style={{ fontWeight: 500 }}>{avis.name}</p>
        <p>{avis.date}</p>
      </div>
      <p style={{ fontWeight: 400 }}>{avis.desc}</p>
    </div>
  );
};

export { RecoBox };
