import React from 'react';
import styled from 'styled-components';
import { style } from '../config';

const Company = styled.div`
  font-weight: 600;
  font-size: 20px;
`;
const Title = styled.div`
  font-weight: 500;
  font-size: 18px;
`;
const Date = styled.div`
  font-weight: 400;
  font-size: 16px;
`;
const Place = styled.div`
  font-weight: 200;
  font-size: 14px;
  color: ${style.primary}AA;
`;
const Details = styled.div`
  margin-top: 8px;
  padding-left: 16px;
  border-left: 1px solid ${style.secondary};
`;

const XPBox = ({
  xp = {
    company: 'COPRISTO',
    imgSrc: '',
    imgAlt: '',
    date: '2019-2023',
    jobs: [
      {
        title: 'Cx0',
        date: "Septembre 2019 à Aujourd'hui",
        place: 'Boulogne-Billancourt',
        description: 'Lorem ipsum dolores description',
      },
    ],
  },
}) => {
  return (
    <div style={{ marginTop: 16 }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {xp.imgSrc && (
          <img
            src={xp.imgSrc || ''}
            alt={xp.imgAlt || ''}
            style={{ height: 60, width: 60, marginRight: 10, objectFit: 'contain' }}
          />
        )}
        <div className='company'>
          <Company>{xp.company}</Company>
          {xp.jobs.length === 1 && <Title>{xp.jobs[0].title}</Title>}
          <Date>{xp.date}</Date>
          <Place>{xp.jobs[0].place}</Place>
        </div>
      </div>
      <Details>
        {xp.jobs.length > 1
          ? xp.jobs.map((job) => (
              <div key={'xpbox_' + job.title}>
                <Title style={{ marginTop: 16 }}>{job.title}</Title>
                <Date>{job.date}</Date>
                <div>{job.description}</div>
              </div>
            ))
          : xp.jobs[0].description}
      </Details>
    </div>
  );
};

export { XPBox };
