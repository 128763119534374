import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { style } from '../config';
import { CircleDemo } from './StarBoop';

const SLink = (props) => {
  const path = useLocation().pathname;
  return (
    <Link
      className={`routerLink ${
        props.to.search(path) !== -1 && path !== '/' ? 'active' : ''
      }`}
      to={props.to}
      style={{ textDecoration: 'none', color: style.primary, fontWeight: 600 }}
    >
      {props.children}
    </Link>
  );
};

const Navbar = () => {
  return (
    <div className='navbar'>
      <ul>
        <li>
          <CircleDemo />
          <SLink to='/'>Côme Pecorari</SLink>
        </li>
        <li>
          <SLink to='/CV'>CV</SLink>
        </li>
        <li>
          <SLink to='/realisations'>Réalisations</SLink>
        </li>
        <li>
          <SLink to='/recommandations'>Recommandations</SLink>
        </li>
        <li>
          <SLink to='/contact'>Coordonnées</SLink>
        </li>
      </ul>
    </div>
  );
};

export { Navbar };
