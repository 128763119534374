import { HashRouter, Link, Route, Switch } from 'react-router-dom';
import './App.scss';
import { Navbar, Footer, Bold, MainTitle } from './Components';
import { Contact, CV, Recommandations } from './Pages';
import Realisations from './Pages/Realisations';

function App() {
  return (
    <HashRouter>
      <Switch>
        <div className='App'>
          <Navbar />
          {/* <img src={logo} className="App-logo" alt="logo" /> */}
          <Route exact path='/'>
            <div
              style={{
                margin: 16,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <MainTitle />
              <p>
                Ingénieur, d'abord en systèmes embarqués, développeur pour les
                interfaces automobiles innovantes puis chef de projet et
                directeur d'Agence, j'ai décidé de développer une plateforme de
                gestion des copropriétés à mon compte. Je suis également CTO
                associé sur 2 projets de blockchain et cryptomonnaies.
              </p>
              <img
                alt='Copristo Logo'
                src='https://www.copristo.com/images/logo.png'
              ></img>
              <p>
                J'ai construit Copristo sur une architecture{' '}
                <span style={{ fontWeight: 600 }}>
                  MERN (Mongo Express React et Node)
                </span>
                , et les applications iOS et Android grâce à React Native.
              </p>
              <img alt='ETH' width={200} src='/img/ETH-logo.png'></img>
              <p>
                Passionné par les nouvelles technologies et en particulier le
                développement d'applications web et mobile en JS, je cherche des
                projets pour en apprendre encore et mettre à profit mes
                compétences et expériences variées. Ayant des préférences pour
                l'architecture MERN,{' '}
                <Bold>Redis, TypeScript, et la blockchain</Bold>, je suis ouvert
                à d'autres frameworks ou langages comme RUST.
              </p>
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'space-around',
                  marginTop: 16,
                }}
              >
                <Link className='button' to='/CV'>
                  Mes expériences
                </Link>
                <Link className='button' to='/contact'>
                  Me contacter
                </Link>
              </div>
            </div>
          </Route>
          <Route exact path='/realisations'>
            <div style={{ margin: 16 }}>
              <Realisations />
            </div>
          </Route>
          <Route exact path='/CV'>
            <div style={{ margin: 16 }}>
              <CV />
            </div>
          </Route>
          <Route exact path='/recommandations'>
            <div style={{ margin: 16 }}>
              <Recommandations />
            </div>
          </Route>
          <Route exact path='/contact'>
            <div style={{ margin: 16 }}>
              <Contact />
            </div>
          </Route>
          <Footer />
        </div>
      </Switch>
    </HashRouter>
  );
}

export default App;
