import React from 'react';
import { RecoBox } from '../Components';

const Recommandations = () => {
  return (
    <div>
      <RecoBox />
      <RecoBox
        avis={{
          comm: 5,
          delays: 5,
          quality: 5,
          date: '22/10/2020',
          name: 'DBL Conseil',
          desc: (
            <>
              Côme a très bien su m'accompagner à la fois dans les taches
              confiées mais aussi dans la vulgarisation et l'explication d'un
              domaine abstrait pour moi.
              <br />
              La disponibilité, fiabilité et convivialité sont des valeurs
              fortes véhiculés par Côme qui en fait un partenaire nécessaire.
            </>
          ),
        }}
      />
      <RecoBox
        avis={{
          comm: 5,
          delays: 5,
          quality: 5,
          desc: (
            <>
              I was managing Côme when I was in charge of HMI Innovation Tools
              at PSA. I appreciated his open mindset, his ability to enlist his
              ALTEN team and ESG company to bring beneficial HW and graphical SW
              tools to Designers and UX teams.
              <br />
              He helped them to go fast by implementing AGILE methodology, by
              listening their needs, by looping back to optimize, by showing
              their results in some concept cars for evaluation and tests.
              <br />
              When we see the results on graphical displays in the new 3008 at
              5008 PEUGEOT and other brands Citroën and DS, we can be sure that
              his contribution was high to propose a perfect result.
              <br />
              And today it's still working as the Designers appropriated the
              tools and are proud of it. He is keeping continuously the same
              pace and spirit in his new position by focusing the team on the
              end-user, by deeply listening the clients and by determining so
              the best and right offer to his clients for a mutual satisfaction.
            </>
          ),
          date: '14/02/2019',
          name: 'Denis MANCEAU - Directeur Innovation SAFRAN',
        }}
      />
      <RecoBox
        avis={{
          comm: 5,
          delays: 5,
          quality: 5,
          desc: (
            <>
              Côme nous accompagne depuis des années et ALTIM a un
              positionnement fort dans notre service chez PSA. C'est un
              véritable partenaire qui accompagne nos besoins de façon
              pro-active.
              <br />
              Excellent travail depuis toutes ces années !
            </>
          ),
          date: '11/09/2018',
          name: 'Olivier DAILLANCE - Directeur Style IHM PSA',
        }}
      />
      <RecoBox
        avis={{
          comm: 5,
          delays: 5,
          quality: 5,
          desc: (
            <>
              J'ai pu échanger avec Côme sur toutes les phases de son projet
              entrepreneurial : de l'idée, en passant par la conception et très
              vite sur la réalisation.
              <br />
              Il a su s'investir, relever de nombreux challenges afin de sortir
              un produit performant et facile d'utilisation. Je peux donc vous
              assurer qu'il emmène les projets au bout tout en prenant en compte
              les différentes problématiques.
            </>
          ),
          date: '10/12/2020',
          name: 'Mathieu Delion - ALTIM',
        }}
      />
      <RecoBox
        avis={{
          comm: 5,
          delays: 5,
          quality: 5,
          desc: (
            <>
              J'ai eu l'occasion de travailler avec Côme et je recommande ses
              capacités relationnelles et techniques, il a su répondre aux
              enjeux de son poste et je l'ai ressenti dans mon travail.
              <br />
              Je salue sa dernière expérience en tant qu'entrepreneur et
              notamment concernant le développement d'une solution logicielle
              complète, innovante et très bien pensée.
            </>
          ),
          date: '2/11/2020',
          name: 'Axel ROCHE - RATP Smart Systems',
        }}
      />
    </div>
  );
};

export { Recommandations };
