import styled from 'styled-components';

export const Bold = styled.span`
  font-weight: 600;
`;

export const MainTitle = () =>
 (
  <h3>
    Développeur Node, React, Solidity (Blockchain), Express, Redis, MongoDB
  </h3>
);
